import React, { useState } from 'react';
import { Typography, Grid, Box, Container, TextField, Button } from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';

function ContactUs() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  
  const [statusMessage, setStatusMessage] = useState(''); // State to store status message
  const [statusType, setStatusType] = useState(''); // To determine if it's a success or error
  const [recaptchaToken, setRecaptchaToken] = useState(null); // Store the recaptcha token

  // Handle form data changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Handle reCAPTCHA verification
  const handleRecaptcha = (token) => {
    setRecaptchaToken(token); // Store the token once user completes reCAPTCHA
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Ensure reCAPTCHA was completed
    if (!recaptchaToken) {
      setStatusMessage('Please complete the reCAPTCHA');
      setStatusType('error');
      return;
    }

    const apiURL = '/send-email';  // Relative URL for backend API

    try {
      const response = await fetch(apiURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...formData, recaptchaToken }), // Send the reCAPTCHA token with form data
      });

      if (response.ok) {
        setStatusMessage('Your message has been sent! Thank you for contacting us.');
        setStatusType('success');
        setFormData({ name: '', email: '', message: '' });
      } else {
        setStatusMessage('Sorry, there was an issue sending your message. Please try again.');
        setStatusType('error');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setStatusMessage('Sorry, there was an issue sending your message. Please try again.');
      setStatusType('error');
    }
  };

  return (
    <div style={{ backgroundColor: '#f9f9f9', padding: '50px 0', minHeight: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', fontFamily: "'Patrick Hand', sans-serif" }}>
      <Container maxWidth="md" style={{ textAlign: 'center' }}>
        <Typography 
          variant="h3" 
          component="h1" 
          style={{ fontSize: '80px', marginBottom: '20px' }}
        >
          Contact Us
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            backgroundColor: 'white',
            borderRadius: '12px',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
            padding: '20px',
            maxWidth: '700px',
            margin: '0 auto'
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Your Name"
                name="name"
                variant="outlined"
                value={formData.name}
                onChange={handleChange}
                required
                sx={{ fontFamily: "'Patrick Hand', sans-serif" }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Your Email"
                name="email"
                type="email"
                variant="outlined"
                value={formData.email}
                onChange={handleChange}
                required
                sx={{ fontFamily: "'Patrick Hand', sans-serif" }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Your Message"
                name="message"
                variant="outlined"
                multiline
                rows={6}
                value={formData.message}
                onChange={handleChange}
                required
                sx={{ fontFamily: "'Patrick Hand', sans-serif" }}
              />
            </Grid>

            {/* Google reCAPTCHA */}
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <ReCAPTCHA
                sitekey="6LfqN1IqAAAAAOHbm03De7LHMVlb_XGyuGRgUq0G"
                onChange={handleRecaptcha}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{
                  fontFamily: "'Patrick Hand', sans-serif",
                  fontSize: '20px',
                  padding: '10px 0'
                }}
              >
                Send Message
              </Button>
            </Grid>
          </Grid>

          {/* Display the status message below the button */}
          {statusMessage && (
            <Typography
              variant="body1"
              component="p"
              style={{
                marginTop: '20px',
                color: statusType === 'success' ? 'green' : 'red',
              }}
            >
              {statusMessage}
            </Typography>
          )}
        </Box>
      </Container>
    </div>
  );
}

export default ContactUs;
