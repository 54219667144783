import React from 'react';
import { Box, Typography } from '@mui/material';

function Footer() {
  return (
    <Box 
      component="footer"
      sx={{ 
        backgroundColor: '#f5f5f5', 
        padding: '20px', 
        textAlign: 'center', 
        position: 'relative', 
        bottom: 0, 
        width: '100%' 
      }}
    >
      <Typography variant="body2" color="textSecondary">
        © {new Date().getFullYear()} melaniemorganbooks.com. All rights reserved.
      </Typography>
    </Box>
  );
}

export default Footer;
