import React from 'react';
import { Typography, Grid, Box, Container } from '@mui/material';
import melanieClassroom1 from '../assets/images/Melanie_Classroom_1.jpeg'; // Path to your image
import melanieClassroom2 from '../assets/images/Melanie_Classroom_2.jpg'; // Path to your image

function SchoolVisits() {
  return (
    <div style={{ backgroundColor: '#f9f9f9', padding: '0 0 50px 0', minHeight: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', fontFamily: "'Patrick Hand', sans-serif" }}>

      {/* Centered content container */}
      <Container maxWidth="md" style={{ textAlign: 'center' }}>

        {/* Title and Subtitle */}
        <Typography
          variant="h3"
          component="h1"
          style={{ fontSize: '80px', marginTop: '20px', marginBottom: '5px' }}
        >
          Melanie Morgan LOVES visiting schools!
        </Typography>

        <Typography
          variant="h5"
          component="h2"
          style={{ fontSize: '50px', fontWeight: 'normal', marginTop: '0', marginBottom: '20px' }}
        >
          Schedule an Author Visit for grades PK-3<sup>rd</sup>. Sessions can be in English OR Spanish!
        </Typography>

        {/* First Image and description section */}
        <Box
          sx={{
            backgroundColor: 'white',
            borderRadius: '12px',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
            padding: '20px',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            maxWidth: '900px',
            margin: '0 auto',
          }}
        >
          <Grid container spacing={2}>
            {/* First Image */}
            <Grid item xs={12} md={5}>
              <img
                src={melanieClassroom1}
                alt="Melanie Morgan in Classroom"
                style={{
                  width: '100%',
                  maxWidth: '250px',
                  borderRadius: '12px',
                }}
              />
            </Grid>

            {/* Text Section */}
            <Grid item xs={12} md={7}>
              <Typography
                variant="body1"
                style={{
                  fontSize: '40px',
                  lineHeight: '1.7',
                  textAlign: 'left',
                }}
              >
                Melanie provides an interactive 30-60 minute Instructional Read Aloud Session tailored to PK-3<sup>rd</sup> grade students. Sessions can be arranged with either single or multiple grade levels, based on your specific needs.
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Typography
          variant="body1"
          component="p"
          style={{ fontSize: '30px', marginTop: '20px' }}
        >
          As she shares her books, students will have the opportunity to engage with grade-level content related to various literary concepts, such as:
        </Typography>

        <Typography
          variant="body1"
          component="p"
          style={{ fontSize: '22px', marginTop: '10px', marginBottom: '20px' }}
        >
          Rhyming Words, Predictions and Inferences, Comprehension and Fluency, Figurative Language, Storytelling Characteristics, Steps to the Writing Process
        </Typography>

        {/* Second Image and additional description */}
        <Box
          sx={{
            backgroundColor: 'white',
            borderRadius: '12px',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
            padding: '20px',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            maxWidth: '900px',
            margin: '0 auto',
          }}
        >
          <Grid container spacing={2}>
            {/* Second Image */}
            <Grid item xs={12} md={5}>
              <img
                src={melanieClassroom2}
                alt="Melanie Morgan in Classroom"
                style={{
                  width: '100%',
                  maxWidth: '250px',
                  borderRadius: '12px',
                }}
              />
            </Grid>

            {/* Text Section */}
            <Grid item xs={12} md={7}>
              <Typography
                variant="body1"
                style={{
                  fontSize: '35px',
                  lineHeight: '1.7',
                  textAlign: 'left',
                }}
              >
                Sessions are $200 each with a maximum of 100 students per session. Sessions can be scheduled via <a href="mailto:contact@melaniemorganbooks.com" style={{ fontSize: '30px', textDecoration: 'none', color: '#003366' }}>contact@melaniemorganbooks.com</a>.
              </Typography>
            </Grid>
          </Grid>
        </Box>

        {/* Feedback Section */}
        <Box sx={{ marginTop: '40px', textAlign: 'center' }}>
          <Typography
            variant="h4"
            component="h3"
            style={{
              fontSize: '50px',
              fontWeight: 'bold',
              marginBottom: '20px',
            }}
          >
            This Is What They Have Said…
          </Typography>

          <Typography
            variant="body1"
            component="p"
            style={{ fontSize: '22px', marginBottom: '20px' }}
          >
            "Consider Melanie for your next author visit for your younger grades! She’s wonderful. Her performance is highly entertaining and engaging
            for young readers! She’s a wonderful presenter students can engage and laugh with!" – <strong>Shannon Perkins, Librarian, San Antonio, TX</strong><br /><br />

            "My class absolutely loved your visit and books. You did an excellent job keeping their attention, you were engaging and I loved how you
            included them in the story by telling different students what character they would be. This helped them stay focused on the story and
            helped them use their imaginations and relate to the story." -<strong>Charis Rohrs, 2nd Grade Teacher, Lago Vista, TX</strong><br /><br />

            "You did a great job presenting your books to the class. The kids were really attentive and engaged which is hard to do these days. Thanks for
            sharing your books with us!" – <strong>Nancy Baker, 3rd Grade Teacher, Lago Vista, TX</strong><br /><br />

            "Thank you for inspiring our kids and writing such cute books!" – <strong>Lacey Culbreth, 1st Grade Teacher, Lago Vista, TX</strong><br /><br />

            "Thank you so much for coming to our class! The kids LOVED it! I thought you did a fantastic job! I loved how you told the story with
            expression, and you explained as you went along. You allowed the kids to have an opportunity to gain background information about each
            character. They have been talking about you since they met you!" -<strong>Terri Axley, 2nd Grade Teacher, Lago Vista, TX</strong><br /><br />

            "I loved your presentation and it was very interactive for the kiddos!" – <strong>Michelle Heston, 2nd Grade Teacher, Lago Vista, TX</strong><br /><br />

            "Thank you so much for sharing your books with our class. We loved them both, and I loved your
            presentation! That class has some talkers and is easily excited. They were super engaged, and that is impressive! I really liked how you helped
            them make connections to their writing." – <strong>Katie Hayhurst, 3rd Grade Teacher, Lago Vista, TX</strong><br /><br />

            "Today was amazing! The kids and I both loved it! I loved how you talked through your thoughts with writing as you read to the kids. You
            touched on many things we have already talked about this year, so it was great for them to hear it from a real author!" – <strong>Kasey Clanton,
              2nd Grade Teacher, Lago Vista, TX</strong><br /><br />

            <strong>Great Experience!</strong><br />
            "Melanie visited K-3rd grade classrooms at our elementary school to share her books. She did such a great job finding all the teachable
            moments throughout her books. Students were engaged at every level and enjoyed her stories. I highly recommend her books and can't wait
            to share the next with my students!" – <strong>Tricia Vasquez, District Gifted and Talented Specialist, Lago Vista, TX</strong>
          </Typography>

          {/* Amazon Reviews Section */}
          <Typography
            variant="h4"
            component="h3"
            style={{
              fontSize: '50px',
              fontWeight: 'bold',
              marginTop: '40px',
              marginBottom: '20px',
            }}
          >
            Amazon Reviews…
          </Typography>

          <Typography
            variant="body1"
            component="p"
            style={{ fontSize: '22px', marginBottom: '20px' }}
          >
            <strong>Great book for early readers!</strong> <br />
            A great read aloud for early chapter book readers! <br /><br />

            <strong>Amazing author and story!</strong> <br />
            Melanie came to read to my group of 2nd graders and the students remained so engaged. She shared two of her books, explained how she
            used the writing process, and allowed students to share some of their own experiences with reading and writing. <br /><br />

            <strong>Cute story for siblings</strong> <br />
            <strong><u>Bees:</u></strong> My son loved this book because it reminded him of the adventures he has with his brothers. Cute story that developed the characters in
            a simple but relatable format, with a good storyline. Would be great for a classroom lesson using story plot mapping. <br /><br />

            <strong>Relatable Story for Young Kids</strong> <br />
            Loved the rhyming in this book (<strong><u>Mark and the Dark</u></strong>) and also the opportunities to teach prediction and inferencing with my students. They
            all had a story they wanted to share, which tells me they easily saw connections to their own life! I would also highly recommend for kiddos
            who have fears when it comes to bedtime.
          </Typography>
        </Box>
      </Container>
    </div>
  );
}

export default SchoolVisits;