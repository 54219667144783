import React from 'react';
import { Typography, Grid, Box, Container } from '@mui/material';
import melanieImage from '../assets/images/Melanie_Morgan_Image_1.jpeg'; // Replace with the correct image path

function About() {
  return (
    // Background container with no padding or margin on top
    <div style={{ backgroundColor: '#f9f9f9', padding: '0 0 50px 0', minHeight: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', fontFamily: "'Patrick Hand', sans-serif" }}>

      {/* Centered content container */}
      <Container maxWidth="md" style={{ textAlign: 'center', paddingTop: '0', marginTop: '0' }}> {/* Ensure no top padding/margin */}

        {/* Title and Subtitle */}
        <Typography
          variant="h3"
          component="h1"
          style={{ fontSize: '80px', marginTop: '0', marginBottom: '5px' }} // Remove top margin
        >
          About Melanie Morgan
        </Typography>

        {/* Box wrapping the image and all the text with shadow and rounded corners */}
        <Box
          sx={{
            backgroundColor: 'white',
            borderRadius: '12px',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
            padding: '20px',
            maxWidth: '900px',
            margin: '0 auto',
          }}
        >
          <Grid container spacing={2}>
            {/* Image Section */}
            <Grid item xs={12} md={5}>
              <img
                src={melanieImage}
                alt="Melanie Morgan"
                style={{
                  width: '100%',
                  maxWidth: '250px',
                  borderRadius: '12px',
                }}
              />
            </Grid>

            {/* Text Section */}
            <Grid item xs={12} md={7}>
              <Typography
                variant="body1"
                style={{
                  fontSize: '22px', // Consistent font size
                  lineHeight: '1.7',
                  textAlign: 'center', // Changed to center
                }}
              >
                Melanie Morgan is a lifelong Texan! She lives in Leander, Texas with her husband and has three sons who are already grown. Her story ideas come from her experiences growing up in San Antonio, Texas and raising her three boys. Melanie Morgan loves cats and mowing the lawn! Often, you can find her working outside and driving a tractor!
              </Typography>
            </Grid>
          </Grid>

          {/* All remaining text in the same box */}
          <Typography
            variant="body1"
            component="p"
            style={{ fontSize: '22px', marginTop: '20px' }}
          >
            Melanie Morgan is a bilingual and ESL certified Texas teacher with 8 years of classroom experience in elementary grades. She has taught in both Bilingual and English classrooms.
            <br /><br />
            Her children’s books support students in applying and synthesizing the literacy skills learned in the classroom. Melanie Morgan wants to be sure that her books are fun to read and work well as Read Alouds for families and teachers where great conversations about problem-solving and making good choices can happen.
          </Typography>

          <Typography
            variant="body1"
            component="p"
            style={{ fontSize: '22px', marginTop: '20px' }}
          >
            Melanie Morgan’s favorite thing to tell her students and their families about learning to read is the following:
            <br /><br />
            <span style={{ fontStyle: 'italic' }}>
              “In Kinder and 1st grade, students LEARN to read because, in all the grades to follow, students will READ to learn.”
            </span>
          </Typography>

          <Typography
            variant="body1"
            component="p"
            style={{ fontSize: '22px', marginTop: '20px' }}
          >
            Melanie Morgan’s first series of books are written for 1st graders who are reading on levels 12-16. The average 1st grader reads on these levels usually during the second semester of 1st grade around the months of March and April. These books are written with words that have more syllables and more complicated phonograms. Comprehension and Fluency are important factors at this stage of reading and Melanie’s books are intended to help students practice and improve in these skills. This series also works well as a Read Aloud for PreK, Kinder, and 1st grade!
          </Typography>

          <Typography
            variant="body1"
            component="p"
            style={{ fontSize: '22px', marginTop: '20px' }}
          >
            Melanie Morgan’s second series of books are written as beginning chapter books for 1st and 2nd graders. The books chronicle the adventures of a sibling group of 5 children. Many of the stories are based on true events from Melanie Morgan’s younger years. The font is larger print, and there are more spaces between sentences to help the reader track as they read. This series also works well as a Read Aloud for Kinder through 5th grade! Higher grade levels can analyze the text and create story plot maps.
          </Typography>

          <Typography
            variant="body1"
            component="p"
            style={{ fontSize: '22px', marginTop: '20px' }}
          >
            Melanie Morgan’s third series of books formats her books to support Bilingual and Dual Language classrooms and home settings. Each book contains three versions of the story. The first is English, which is followed by the story written in Spanish. Then, the third version is where the English and Spanish are together or side by side.
          </Typography>

          <Box sx={{ marginTop: '40px', textAlign: 'center' }}>
            <Typography
              variant="h4"
              component="h3"
              style={{ fontSize: '50px', fontWeight: 'bold', marginTop: '40px' }}
            >
              Let’s keep on reading!
            </Typography>
          </Box>
        </Box>
      </Container>
    </div>
  );
}

export default About;