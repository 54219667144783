import React, { useState } from 'react';
import { AppBar, Toolbar, Button, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu'; // Import the hamburger icon
import { Link, useLocation } from 'react-router-dom';

function Navbar() {
  const [mobileOpen, setMobileOpen] = useState(false); // State to control the drawer
  const location = useLocation(); // Hook to get the current location

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenuItemClick = () => {
    setMobileOpen(false);
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  const isActive = (path) => location.pathname === path;

  // Drawer content for mobile view
  const drawer = (
    <List>
      <ListItem button component={Link} to="/" onClick={handleMenuItemClick} selected={isActive('/')}>
        <ListItemText primary="Home" sx={{ color: 'black', fontFamily: "'Patrick Hand', sans-serif", fontSize: '22px', fontWeight: isActive('/') ? 'bold' : 'normal', backgroundColor: isActive('/') ? '#f0f0f0' : 'transparent', borderRadius: '4px' }} />
      </ListItem>
      <ListItem button component={Link} to="/books" onClick={handleMenuItemClick} selected={isActive('/books')}>
        <ListItemText primary="Books" sx={{ color: 'black', fontFamily: "'Patrick Hand', sans-serif", fontSize: '22px', fontWeight: isActive('/books') ? 'bold' : 'normal', backgroundColor: isActive('/books') ? '#f0f0f0' : 'transparent', borderRadius: '4px' }} />
      </ListItem>
      <ListItem button component={Link} to="/school-visits" onClick={handleMenuItemClick} selected={isActive('/school-visits')}>
        <ListItemText primary="School Visits" sx={{ color: 'black', fontFamily: "'Patrick Hand', sans-serif", fontSize: '22px', fontWeight: isActive('/school-visits') ? 'bold' : 'normal', backgroundColor: isActive('/school-visits') ? '#f0f0f0' : 'transparent', borderRadius: '4px' }} />
      </ListItem>
      <ListItem button component={Link} to="/about" onClick={handleMenuItemClick} selected={isActive('/about')}>
        <ListItemText primary="About" sx={{ color: 'black', fontFamily: "'Patrick Hand', sans-serif", fontSize: '22px', fontWeight: isActive('/about') ? 'bold' : 'normal', backgroundColor: isActive('/about') ? '#f0f0f0' : 'transparent', borderRadius: '4px' }} />
      </ListItem>
      <ListItem button component={Link} to="/contact-us" onClick={handleMenuItemClick} selected={isActive('/contact-us')}>
        <ListItemText primary="Contact Us" sx={{ color: 'black', fontFamily: "'Patrick Hand', sans-serif", fontSize: '22px', fontWeight: isActive('/contact-us') ? 'bold' : 'normal', backgroundColor: isActive('/contact-us') ? '#f0f0f0' : 'transparent', borderRadius: '4px' }} />
      </ListItem>
    </List>
  );

  return (
    <>
      {/* AppBar for Navbar */}
      <AppBar
        position="fixed" // Changed to fixed to keep the navbar visible while scrolling
        style={{ backgroundColor: '#fff', color: '#000', padding: '0', marginBottom: '0', fontFamily: "'Patrick Hand', sans-serif" }}
      >
        <Toolbar style={{ justifyContent: 'space-between', padding: '0', marginBottom: '0' }}>
          {/* Hamburger Icon for Mobile */}
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ display: { xs: 'block', md: 'none' }, ml: 2 }} // Added left margin (ml: 2) for padding
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>

          {/* Menu for Desktop */}
          <div style={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
            <Button
              sx={{
                fontSize: '30px',
                padding: '0 20px',
                display: { xs: 'none', md: 'inline' },
                fontFamily: "'Patrick Hand', sans-serif", // Apply Patrick Hand font
                fontWeight: isActive('/') ? 'bold' : 'normal',
                backgroundColor: isActive('/') ? '#f0f0f0' : 'transparent',
                color: isActive('/') ? 'black' : 'inherit',
                ':hover': {
                  fontWeight: 'bold', // Bold on hover
                  backgroundColor: '#f0f0f0', // Subtle background on hover
                },
              }}
              component={Link}
              to="/"
              color="inherit"
            >
              Home
            </Button>
            <Button
              sx={{
                fontSize: '30px',
                padding: '0 20px',
                display: { xs: 'none', md: 'inline' },
                fontFamily: "'Patrick Hand', sans-serif", // Apply Patrick Hand font
                fontWeight: isActive('/books') ? 'bold' : 'normal',
                backgroundColor: isActive('/books') ? '#f0f0f0' : 'transparent',
                color: isActive('/books') ? 'black' : 'inherit',
                ':hover': {
                  fontWeight: 'bold', // Bold on hover
                  backgroundColor: '#f0f0f0', // Subtle background on hover
                },
              }}
              component={Link}
              to="/books"
              color="inherit"
            >
              Books
            </Button>
            <Button
              sx={{
                fontSize: '30px',
                padding: '0 20px',
                display: { xs: 'none', md: 'inline' },
                fontFamily: "'Patrick Hand', sans-serif", // Apply Patrick Hand font
                fontWeight: isActive('/school-visits') ? 'bold' : 'normal',
                backgroundColor: isActive('/school-visits') ? '#f0f0f0' : 'transparent',
                color: isActive('/school-visits') ? 'black' : 'inherit',
                ':hover': {
                  fontWeight: 'bold', // Bold on hover
                  backgroundColor: '#f0f0f0', // Subtle background on hover
                },
              }}
              component={Link}
              to="/school-visits"
              color="inherit"
            >
              School Visits
            </Button>
            <Button
              sx={{
                fontSize: '30px',
                padding: '0 20px',
                display: { xs: 'none', md: 'inline' },
                fontFamily: "'Patrick Hand', sans-serif", // Apply Patrick Hand font
                fontWeight: isActive('/about') ? 'bold' : 'normal',
                backgroundColor: isActive('/about') ? '#f0f0f0' : 'transparent',
                color: isActive('/about') ? 'black' : 'inherit',
                ':hover': {
                  fontWeight: 'bold', // Bold on hover
                  backgroundColor: '#f0f0f0', // Subtle background on hover
                },
              }}
              component={Link}
              to="/about"
              color="inherit"
            >
              About
            </Button>
            <Button
              sx={{
                fontSize: '30px',
                padding: '0 20px',
                display: { xs: 'none', md: 'inline' },
                fontFamily: "'Patrick Hand', sans-serif", // Apply Patrick Hand font
                fontWeight: isActive('/contact-us') ? 'bold' : 'normal',
                backgroundColor: isActive('/contact-us') ? '#f0f0f0' : 'transparent',
                color: isActive('/contact-us') ? 'black' : 'inherit',
                ':hover': {
                  fontWeight: 'bold', // Bold on hover
                  backgroundColor: '#f0f0f0', // Subtle background on hover
                },
              }}
              component={Link}
              to="/contact-us"
              color="inherit"
            >
              Contact Us
            </Button>
          </div>
        </Toolbar>
      </AppBar>

      {/* Drawer for Mobile Menu */}
      <Drawer
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{ display: { xs: 'block', md: 'none' } }} // Only display on mobile
      >
        {drawer}
      </Drawer>

      {/* Add a spacer div to account for the height of the fixed navbar */}
      <div style={{ height: '64px' }} /> {/* Adjust height based on your AppBar height */}
    </>
  );
}

export default Navbar;