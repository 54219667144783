import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider, CssBaseline } from '@mui/material';
import Navbar from './components/Navbar';
import Footer from './components/Footer';  // Import Footer
import Home from './pages/Home';
import Books from './pages/Books';
import SchoolVisits from './pages/SchoolVisits';
import About from './pages/About';
import ContactUs from './pages/ContactUs';

// Create a theme with Comic Neue font from Google Fonts
const theme = createTheme({
  typography: {
    fontFamily: 'Comic Neue, sans-serif',  // Updated font
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/books" element={<Books />} />
          <Route path="/school-visits" element={<SchoolVisits />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact-us" element={<ContactUs />} />
        </Routes>
        <Footer />  {/* Add Footer here */}
      </Router>
    </ThemeProvider>
  );
}

export default App;
