import React from 'react';
import { Typography, Grid, Box, Button, Container } from '@mui/material';
import bookEnglish1 from '../assets/images/Bill.png';
import bookSpanish1 from '../assets/images/Beto.png';
import bookDual1 from '../assets/images/BillBetoPaperback.png';
import bookEnglish2 from '../assets/images/Mark.png';
import bookSpanish2 from '../assets/images/Arturo.png';
import bookDual2 from '../assets/images/MarkArturoPaperback.png';
import bookEnglish3 from '../assets/images/Bees.png';
import bookSpanish3 from '../assets/images/Abejas.png';
import bookDual3 from '../assets/images/BeesAbejas.png';

function Books() {
    return (
        <div style={{ backgroundColor: '#f9f9f9', padding: '50px 0', fontFamily: "'Patrick Hand', sans-serif" }}>
            <Container maxWidth="md" style={{ textAlign: 'center' }}>
                <Typography variant="h3" component="h1" style={{ fontSize: '60px' }}>
                    All of Melanie Morgan's books come in three formats:
                </Typography>
                <Typography variant="h5" component="h2" style={{ fontSize: '40px', margin: '20px 0' }}>
                    English <br /> Spanish <br /> Dual Language (English and Spanish together)
                </Typography>

                {/* First Book Section */}
                <Box
                    sx={{
                        backgroundColor: 'white',
                        borderRadius: '12px',
                        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                        padding: '20px',
                        margin: '40px 0',
                    }}
                >
                    <Grid container spacing={2}>
                        {/* Book Images */}
                        <Grid item xs={12} md={4} style={{ textAlign: 'center' }}>
                            <img src={bookEnglish1} alt="Bill Did Not Sit Still - English" style={{ width: '100%', maxWidth: '200px' }} />
                            <a href="https://www.amazon.com/Bill-Did-Not-Sit-Still/dp/B0BVCTQFC7" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                <Button variant="contained" style={{ marginTop: '10px', display: 'block', margin: '10px auto' }}>Buy Now</Button>
                            </a>
                        </Grid>
                        <Grid item xs={12} md={4} style={{ textAlign: 'center' }}>
                            <img src={bookSpanish1} alt="Beto no se sentaba quieto - Spanish" style={{ width: '100%', maxWidth: '200px' }} />
                            <a href="https://www.amazon.com/sentaba-quieto-Spanish-Melanie-Morgan/dp/B0CJHH49RX/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                <Button variant="contained" style={{ marginTop: '10px', display: 'block', margin: '10px auto' }}>Buy Now</Button>
                            </a>
                        </Grid>
                        <Grid item xs={12} md={4} style={{ textAlign: 'center' }}>
                            <img src={bookDual1} alt="Bill Did Not Sit Still - Dual Language" style={{ width: '100%', maxWidth: '200px' }} />
                            <a href="https://www.amazon.com/Bill-Still-Dual-Language-Stories/dp/B0CJL28LR2/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                <Button variant="contained" style={{ marginTop: '10px', display: 'block', margin: '10px auto' }}>Buy Now</Button>
                            </a>
                        </Grid>

                        {/* Book Description */}
                        <Grid item xs={12} style={{ textAlign: 'center', marginTop: '20px' }}>
                            <Typography variant="h6" component="p" style={{ fontSize: '24px' }}>
                                “Bill could not get it right.” Bill is having trouble sitting still in school. Can Bill find a solution to his problem?
                            </Typography>
                            <Typography variant="body1" component="p" style={{ fontSize: '18px', marginTop: '20px' }}>
                            <strong><u>Bill Did Not Sit Still</u></strong> is a story for beginner to intermediate readers, ages 5-7. The font is written in a larger print size and
                                the rhyming pairs of words assist children with decoding while they read. Simple illustrations also help children use picture clues to read
                                unfamiliar words as they discover how Bill finds a solution to his problem.
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>

                {/* Repeat the same changes for the other book sections */}

                {/* Second Book Section */}
                <Box
                    sx={{
                        backgroundColor: 'white',
                        borderRadius: '12px',
                        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                        padding: '20px',
                        margin: '40px 0',
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4} style={{ textAlign: 'center' }}>
                            <img src={bookEnglish2} alt="Mark and the Dark - English" style={{ width: '100%', maxWidth: '200px' }} />
                            <a href="https://www.amazon.com/Mark-Dark-Melanie-Morgan/dp/B0BW2BX6KD/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                <Button variant="contained" style={{ marginTop: '10px', display: 'block', margin: '10px auto' }}>Buy Now</Button>
                            </a>
                        </Grid>
                        <Grid item xs={12} md={4} style={{ textAlign: 'center' }}>
                            <img src={bookSpanish2} alt="Arturo y el oscuro - Spanish" style={{ width: '100%', maxWidth: '200px' }} />
                            <a href="https://www.amazon.com/Arturo-y-el-Oscuro-Spanish/dp/B0CFWZ57SF/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                <Button variant="contained" style={{ marginTop: '10px', display: 'block', margin: '10px auto' }}>Buy Now</Button>
                            </a>
                        </Grid>
                        <Grid item xs={12} md={4} style={{ textAlign: 'center' }}>
                            <img src={bookDual2} alt="Mark and the Dark - Dual Language" style={{ width: '100%', maxWidth: '200px' }} />
                            <a href="https://www.amazon.com/Mark-Dark-Written-English-Language/dp/B0CFZL14K9/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                <Button variant="contained" style={{ marginTop: '10px', display: 'block', margin: '10px auto' }}>Buy Now</Button>
                            </a>
                        </Grid>

                        <Grid item xs={12} style={{ textAlign: 'center', marginTop: '20px' }}>
                            <Typography variant="h6" component="p" style={{ fontSize: '24px' }}>
                                “Things are so different in the dark.” Mark is having trouble sleeping at night. Can Mark find a solution to his problem?
                            </Typography>
                            <Typography variant="body1" component="p" style={{ fontSize: '18px', marginTop: '20px' }}>
                              <strong><u>Mark and the Dark</u></strong> is a story for beginner to intermediate readers, ages 5-7. The font is written in a larger print size and the
                                rhyming pairs of words assist children with decoding while they read. Simple illustrations help children use picture clues to read unfamiliar
                                words and make predictions as they discover what Mark is actually seeing in the dark.
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>

                {/* Third Book Section */}
                <Box
                    sx={{
                        backgroundColor: 'white',
                        borderRadius: '12px',
                        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                        padding: '20px',
                        margin: '40px 0',
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4} style={{ textAlign: 'center' }}>
                            <img src={bookEnglish3} alt="Bees - English" style={{ width: '100%', maxWidth: '200px' }} />
                            <a href="https://www.amazon.com/Bees-Baker-Adventure-Melanie-Morgan/dp/B0CGTP3SK9/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                <Button variant="contained" style={{ marginTop: '10px', display: 'block', margin: '10px auto' }}>Buy Now</Button>
                            </a>
                        </Grid>
                        <Grid item xs={12} md={4} style={{ textAlign: 'center' }}>
                            <img src={bookSpanish3} alt="Abejas - Spanish" style={{ width: '100%', maxWidth: '200px' }} />
                            <a href="https://www.amazon.com/Abejas-Aventura-Escuadrón-Language-Beginning/dp/B0CFZC3RM8/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                <Button variant="contained" style={{ marginTop: '10px', display: 'block', margin: '10px auto' }}>Buy Now</Button>
                            </a>
                        </Grid>
                        <Grid item xs={12} md={4} style={{ textAlign: 'center' }}>
                            <img src={bookDual3} alt="Bees - Dual Language" style={{ width: '100%', maxWidth: '200px' }} />
                            <a href="https://www.amazon.com/Bees-Abejas-Dual-Language-Stories/dp/B0CGTMWR8D/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                <Button variant="contained" style={{ marginTop: '10px', display: 'block', margin: '10px auto' }}>Buy Now</Button>
                            </a>
                        </Grid>

                    <Grid item xs={12} style={{ textAlign: 'center', marginTop: '20px' }}>
                        <Typography variant="h6" component="p" style={{ fontSize: '24px' }}>
                            “Do you know the sound that a swarm of bees makes? I started to run.” The adventure in this true story is how 5 siblings handle the situation!
                        </Typography>
                        <Typography variant="body1" component="p" style={{ fontSize: '18px', marginTop: '20px' }}>
                            <strong><u>Bees: A Baker Squad Adventure</u></strong> is a story for beginner chapter book readers, ages 6-8. The font is a larger print, and the sentences
                            are separated more often to help this age group track more easily while they read.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Container>
        </div >
    );
}

export default Books;