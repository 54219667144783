import React from 'react';
import { Typography, Grid, Box, Container } from '@mui/material';
import melanieImage from '../assets/images/Melanie_Morgan_Home_Page.jpg'; // Replace with the correct image path

function Home() {
  return (
    // Background container with no padding or margin on top
    <div style={{ backgroundColor: '#f9f9f9', padding: '0 0 50px 0', minHeight: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', fontFamily: "'Patrick Hand', sans-serif" }}>

      {/* Centered content container */}
      <Container maxWidth="md" style={{ textAlign: 'center', paddingTop: '0', marginTop: '0' }}> {/* Ensure no top padding/margin */}

        {/* Title and Subtitle */}
        <Typography
          variant="h3"
          component="h1"
          style={{ fontSize: '80px', marginTop: '0', marginBottom: '5px' }} // Remove top margin
        >
          Melanie Morgan
        </Typography>

        <Typography
          variant="h5"
          component="h2"
          style={{ fontSize: '50px', fontWeight: 'normal', marginTop: '0', marginBottom: '5px' }} // Remove top margin
        >
          Bilingual Children's Book Author
        </Typography>

        {/* Squiggly line below title */}
        <div style={{ marginBottom: '20px' }}>
          <svg height="30" width="800"> {/* Adjusted to twice the length (800px width) */}
            <path d="M0 15 Q50 0 100 15 T200 15 T300 15 T400 15 T500 15 T600 15 T700 15 T800 15" stroke="#003366" strokeWidth="4" fill="transparent" />
          </svg>
        </div>

        {/* Box wrapping the image and text with shadow and rounded corners */}
        <Box
          sx={{
            backgroundColor: 'white',
            borderRadius: '12px',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
            padding: '20px',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            maxWidth: '900px',
            margin: '0 auto',
          }}
        >
          <Grid container spacing={2}>
            {/* Image Section */}
            <Grid item xs={12} md={5}>
              <img
                src={melanieImage}
                alt="Melanie Morgan"
                style={{
                  width: '100%',
                  maxWidth: '250px',
                  borderRadius: '12px',
                }}
              />
            </Grid>

            {/* Text Section */}
            <Grid item xs={12} md={7}>
              <Typography
                variant="body1"
                style={{
                  fontSize: '40px',
                  lineHeight: '1.7',
                  textAlign: 'center', // Change this to center
                }}
              >
                Hello! My name is Melanie Morgan and I LOVE writing children's books for Pk-3rd graders.
                I also LOVE visiting schools to share my books and my process for writing!
              </Typography>
            </Grid>

          </Grid>
        </Box>
      </Container>
    </div>
  );
}

export default Home;